<template>
  <div :class="$style.container" ref="dropdown">
    <span :class="$style.arrow"></span>
    <div :class="$style.header">
      <h2>{{ title }}</h2>
    </div>
    <div :class="$style.filters">
      <div :class="$style.filter">
        <p>Тип бизнеса:</p>
        <el-select
          :value="value.type"
          filterable
          placeholder="Выбрать"
          clearable
          remote
          @change="$emit('input', { ...value, type: $event })"
        >
          <el-option
            v-for="type in options.types"
            :key="type.value"
            :label="type.text"
            :value="type.value"
          >
            <span>{{ type.text }}</span>
          </el-option>
        </el-select>
      </div>
      <div :class="$style.filter">
        <p>Начиная с:</p>
        <el-date-picker
          :value="value.startAt"
          type="date"
          placeholder="Pick a Date"
          format="yyyy/MM/dd"
          @input="$emit('input', { ...value, startAt: $event })"
        />
      </div>
      <div :class="$style.filter">
        <p>Заканчивая:</p>
        <el-date-picker
          :value="value.endAt"
          type="date"
          placeholder="Pick a Date"
          format="yyyy/MM/dd"
          @input="$emit('input', { ...value, endAt: $event })"
        />
      </div>
      <div :class="$style.filter">
        <p>Наличие скидки Ип:</p>
        <el-select
          :value="value.ieCartDiscountIsAvailable"
          placeholder="Выбрать"
          @change="
            $emit('input', { ...value, ieCartDiscountIsAvailable: $event })
          "
        >
          <el-option
            v-for="type in options.booleans"
            :key="type.value"
            :label="type.name"
            :value="type.value"
          >
            <span>{{ type.name }}</span>
          </el-option>
        </el-select>
      </div>
      <div :class="$style.filter">
        <p>Наличие скидки ООО:</p>
        <el-select
          :value="value.llcCartDiscountIsAvailable"
          placeholder="Выбрать"
          @change="
            $emit('input', { ...value, llcCartDiscountIsAvailable: $event })
          "
        >
          <el-option
            v-for="type in options.booleans"
            :key="type.value"
            :label="type.name"
            :value="type.value"
          >
            <span>{{ type.name }}</span>
          </el-option>
        </el-select>
      </div>
      <div :class="$style.filter">
        <p>Наличие скидки УС:</p>
        <el-select
          :value="value.rwCartDiscountIsAvailable"
          placeholder="Выбрать"
          @change="
            $emit('input', { ...value, rwCartDiscountIsAvailable: $event })
          "
        >
          <el-option
            v-for="type in options.booleans"
            :key="type.value"
            :label="type.name"
            :value="type.value"
          >
            <span>{{ type.name }}</span>
          </el-option>
        </el-select>
      </div>
      <div :class="$style.filter">
        <p>Забанен:</p>
        <el-select
          :value="value.isBanned"
          placeholder="Выбрать"
          @change="$emit('input', { ...value, isBanned: $event })"
        >
          <el-option
            v-for="type in options.booleans"
            :key="type.value"
            :label="type.name"
            :value="type.value"
          >
            <span>{{ type.name }}</span>
          </el-option>
        </el-select>
      </div>
      <div :class="$style.filter">
        <p>Участвует в БП:</p>
        <el-select
          :value="value.memberBP"
          placeholder="Выбрать"
          @change="$emit('input', { ...value, memberBP: $event })"
        >
          <el-option
            v-for="type in options.booleans"
            :key="type.value"
            :label="type.name"
            :value="type.value"
          >
            <span>{{ type.name }}</span>
          </el-option>
        </el-select>
      </div>
      <div :class="$style.filter">
        <p>Менедежер:</p>
        <el-select
          :value="value.managerId"
          placeholder="Выбрать"
          filterable
          clearable
          remote
          v-el-select-loadmore="loadMoreManagers"
          @change="$emit('input', { ...value, managerId: $event })"
        >
          <el-option
            v-for="manager in options.managers.data"
            :key="manager.id"
            :label="manager.name"
            :value="manager.id"
          >
            <span>{{ manager.name }}</span>
          </el-option>
        </el-select>
      </div>
      <div :class="$style.filter">
        <p>Каталог:</p>
        <el-select
          :value="value.dealerCatalogId"
          placeholder="Выбрать"
          filterable
          clearable
          remote
          v-el-select-loadmore="loadMoreManagers"
          @change="$emit('input', { ...value, dealerCatalogId: $event })"
        >
          <el-option
            v-for="catalog in options.catalogs.data"
            :key="catalog.id"
            :label="catalog.name"
            :value="catalog.id"
          >
            <span>{{ catalog.name }}</span>
          </el-option>
        </el-select>
      </div>
      <div :class="$style.filter">
        <p>Ценовая политика:</p>
        <el-select
          :value="value.pricePolicyId"
          filterable
          clearable
          remote
          v-el-select-loadmore="loadMorePrices"
          @change="$emit('input', { ...value, pricePolicyId: $event })"
          placeholder="Выбрать"
        >
          <el-option
            v-for="policy in options.pricesPolicy.data"
            :key="policy.id"
            :label="policy.name"
            :value="policy.id"
          >
            <span>{{ policy.name }}</span>
          </el-option>
        </el-select>
      </div>
      <div :class="$style.filter">
        <p>Создан менеджером:</p>
        <el-select
          :value="value.managerCoordinatorId"
          placeholder="Выбрать"
          filterable
          clearable
          remote
          @change="$emit('input', { ...value, managerCoordinatorId: $event })"
          v-el-select-loadmore="loadMoreManagersCoordinators"
        >
          <el-option
            v-for="managerCoordinator in options.managersCoordinators.data"
            :key="managerCoordinator.id"
            :label="managerCoordinator.name"
            :value="managerCoordinator.id"
          >
            <span>{{ managerCoordinator.name }}</span>
          </el-option>
        </el-select>
      </div>
      <div :class="$style.filter">
        <p>Поставщик:</p>
        <el-select
          :value="value.isSupplier"
          placeholder="Выбрать"
          @change="$emit('input', { ...value, isSupplier: $event })"
        >
          <el-option
            v-for="type in options.booleans"
            :key="type.value"
            :label="type.name"
            :value="type.value"
          >
            <span>{{ type.name }}</span>
          </el-option>
        </el-select>
      </div>
      <div :class="$style.actions">
        <el-button :class="$style.button" @click="$emit('applyFilters')"
          >Применить</el-button
        >
        <el-button :class="$style.button" @click="$emit('resetFilters')"
          >Сбросить</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: Boolean,
    title: String,
    options: {
      type: Object,
      default: () => ({
        booleans: [],
        types: [],
        pricesPolicy: [],
        managers: [],
        managersCoordinators: [],
        createdManager: [],
      }),
    },
    value: {
      type: Object,
      default: () => ({
        isBanned: null,
        ieCartDiscountIsAvailable: null,
        llcCartDiscountIsAvailable: null,
        rwCartDiscountIsAvailable: null,
        memberBP: null,
        isSupplier: null,
        type: '',
        pricePolicyId: '',
        managerId: '',
        managerCoordinatorId: '',
        startAt: '',
        endAt: '',
      }),
    },
  },
  mounted() {
    setTimeout(() => document.addEventListener('click', this.handleClick), 0)
  },
  destroyed() {
    document.removeEventListener('click', this.handleClick)
  },
  methods: {
    handleClick(event) {
      if (!this.$refs.dropdown.contains(event.target) && this.isOpen) {
        this.$emit('close')
      }
    },
    async loadMoreManagers() {
      this.$emit('loadMoreManagers')
    },
    async loadMorePrices() {
      this.$emit('loadMorePrices')
    },
    async loadMoreManagersCoordinators() {
      this.$emit('loadMoreManagersCoordinators')
    },
  },
}
</script>

<style lang="scss" module>
.container {
  padding-top: 0.75rem;
  position: absolute;
  left: 0;
  top: 3.55rem;
  margin-left: -110%;
  height: 80vh;
  overflow-y: auto;
  background-color: $light-gray;
  color: $black;
  z-index: 10;
  width: 29rem;
  border-radius: 0.25rem;
  .header {
    text-align: center;
    h2 {
      color: $black;
    }
  }
  .arrow {
    width: 1rem;
    height: 1rem;
    position: absolute;
    background-color: $light-gray;
    transform: rotate(45deg);
    top: -0.4rem;
    left: 58%;
    z-index: 20;
  }
  .filters {
    padding: 1rem;
    .actions {
      margin-top: 1rem;
      display: flex;
      justify-content: center;
    }
    .filter {
      margin-top: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        margin-right: 1rem;
      }
      .checkbox {
        position: relative;
        display: inline-block;
        input {
          position: absolute;
          opacity: 0;
          cursor: default;
          height: 0;
          width: 0;
        }
        .activeCheckmark {
          background-color: $black;
          border: 0;
        }
        .label {
          margin-left: 1.5rem;
        }
      }
    }
  }
}
</style>
